
/* ::::::::::::::::::: APP STARTS HERE :::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */


//console.clear();


import 'swiper/css';
import "../scss/style.scss"; // loading in the style
import { gsap } from "gsap"; // loading in the green sock library
import CreateAnnouncement from "./CreateAnnouncement.js";
import CreateFeaturedItem from "./CreateFeaturedItem.js";
import CreateListOfArticles from "./CreateListOfArticles.js";
import CreateFooter from "./CreateFooter.js";
import CreateHeader from "./CreateHeader.js";
import CreateMobileMenu from "./CreateMobileMenu.js";
import CreateSingleArticle from "./CreateSingleArticle.js";
import CreateLeftRail from "./CreateLeftRail.js";
import LoadJSON from "./LoadJSON.js";
import LoadJSON2 from "./LoadJSON2.js";
import Debug from "./Debug.js";


/* ::::::::::::: GLOBAL VARIALBES :::::::::::::: */
/* ::::::::::::::::::::::::::::::::::::::::::::: */


async function init () {

   
    console.log("index-init");

  

    new LoadJSON({
        "url": './js/data/announcement.json',
        "callback": function(data) {
            new CreateAnnouncement({
                "container": `.accouncements`,
                "data": data.content
            });
        }
    });

   // AccounceMent.reset();



   // async_fetch('./js/data/featured-article.json').then( console.log("yo mtv",this))


    new LoadJSON({
        "url": './js/data/mobile-menu.json',
        "callback": function(data) {
            let MMenu = new CreateMobileMenu(data);
        }
    });


    // new LoadJSON({
    //     "url": './js/data/featured-article.json',
    //     "callback": function(data) {
    //         let FeaturedElement = new CreateFeaturedItem(data);
    //     }
    // })

    // new LoadJSON2({
    //     "url": './js/data/featured-article.json',
    //     "callback": function(data) {
    //         console.log(data);
    //         let FeaturedElement = new CreateFeaturedItem(data);
    //     }
    // })

   
    //FeaturedElement.reset();


    /* ::::::: Left Rail ::::::::::::::::::::::: */
    /* ::::::::::::::::::::::::::::::::::::::::: */

    let LeftRail = new CreateLeftRail({
        "container": `.left-rail`,
        "logo": `/assets/logo.png`
    });

    let SearchBox = new LoadJSON({
        "url": './js/data/searchbox.json',
        "callback": function(data) { LeftRail.LoadSearch(data);}
    });

    let BookInfo = new LoadJSON({
        "url": './js/data/book-information.json',
        "callback": function(data) { LeftRail.LoadBookInformation(data);}
    });

    let TagCloud = new LoadJSON({
        "url": './js/data/tag-cloud.json',
        "callback": function(data) { LeftRail.LoadTagCloud(data); }
    });

    /* ------------------------------------------ */



    let ArticleList = new CreateListOfArticles({"container": `.articleList`});
    new LoadJSON({
        "url": './js/data/intropagelist.json',
        "callback": function(data) {ArticleList.load (data)}
    })
    

    //ArticleList.reset();


    /*
    let SingleArticle = new LoadJSON({
        "url": './js/data/1234.json',
        "callback": function(data) { let tempArticle = new CreateSingleArticle(data); }
    })
    */

    
    //tempArticle.reset();


    let Footer = new LoadJSON({
        "url": './js/data/footer-data.json',
        "callback": function(data) { let tempFooter = new CreateFooter(data); }
    })

    let Header = new LoadJSON({
        "url": './js/data/header-data.json',
        "callback": function(data) { let tempFooter = new CreateHeader(data); }
    })


}


init ();

