

//import "../scss/modules/article-snippet.scss"; 
import CreateShareIcons from "./CreateShareIcons.js";
import CreateTags from "./CreateTags.js";

export default class CreateArticlePreview {

    
    constructor (data ) {

        let Scope = this;
        let TargetPageURL = "./article-page.html";

        let container = data["container"]; 
        Scope.container = container;

        let eachArticle = document.createElement("li");
        eachArticle.setAttribute("data-id",data["id"]);
        eachArticle.setAttribute("data-type",data["type"]);
        eachArticle.setAttribute("data-url",data["url"]);
        eachArticle.setAttribute("class","each-article");
        eachArticle.classList.add("article-type-" + data["type"]);
        container.appendChild(eachArticle);

        let eachArticleTitle = document.createElement("div");
        eachArticleTitle.setAttribute("class","title");
        eachArticle.appendChild(eachArticleTitle);
        eachArticleTitle.innerHTML = data["title"];
        eachArticleTitle.addEventListener("click",(event) => {
            let articleID = event.target.parentElement.dataset["id"];
            let articleType = event.target.parentElement.dataset["type"];
            let articleURL = event.target.parentElement.dataset["url"];
            console.groupCollapsed("article preview clicked");
            console.log("article ID: ",articleID);
            console.log("article type: ", articleType);
            console.log("article url: ", articleURL);
            console.groupEnd();

            window.open(TargetPageURL + "?id=" + articleID + "&type=" + articleType, "_self" );
        })

        let eachArticleDate = document.createElement("div");
        eachArticleDate.setAttribute("class","date");
        eachArticleTitle.appendChild(eachArticleDate);
        eachArticleDate.innerHTML = data["date"];

        if (data["type"] == "audio") {
            let audioWaveGraphics = document.createElement("div");
            audioWaveGraphics.setAttribute("class","audio-wave");
            eachArticle.appendChild(audioWaveGraphics);
            audioWaveGraphics.addEventListener("click",(event) => {
                let articleID = event.target.parentElement.dataset["id"];
                let articleType = event.target.parentElement.dataset["type"];
                let articleURL = event.target.parentElement.dataset["url"];
                console.groupCollapsed("article preview clicked");
                console.log("article ID: ",articleID);
                console.log("article type: ", articleType);
                console.log("article url: ", articleURL);
                console.groupEnd();

                window.open(TargetPageURL + "?id=" + articleID + "&type=" + articleType, "_self" );
            })
    
        }

        if (data["type"] == "video") {
            let videoIconGraphics = document.createElement("div");
            videoIconGraphics.setAttribute("class","video-wave");
            eachArticle.appendChild(videoIconGraphics);
            videoIconGraphics.addEventListener("click",(event) => {
                let articleID = event.target.parentElement.dataset["id"];
                let articleType = event.target.parentElement.dataset["type"];
                let articleURL = event.target.parentElement.dataset["url"];
                console.groupCollapsed("article preview clicked");
                console.log("article ID: ",articleID);
                console.log("article type: ", articleType);
                console.log("article url: ", articleURL);
                console.groupEnd();

                window.open(TargetPageURL + "?id=" + articleID + "&type=" + articleType, "_self" );
            })
        }

        let eachArticleSummary = document.createElement("div");
        eachArticleSummary.setAttribute("class","summary");
        eachArticle.appendChild(eachArticleSummary);
        eachArticleSummary.innerHTML = data["summary"];
        eachArticleSummary.addEventListener("click",(event) => {
            let articleID = event.target.parentElement.dataset["id"];
            let articleType = event.target.parentElement.dataset["type"];
            let articleURL = event.target.parentElement.dataset["url"];
            console.groupCollapsed("article preview clicked");
            console.log("article ID: ",articleID);
            console.log("article type: ", articleType);
            console.log("article url: ", articleURL);
            console.groupEnd();

            window.open(TargetPageURL + "?id=" + articleID + "&type=" + articleType, "_self" );
            //console.groupCollapsed();
        })


        let CRtags = new CreateTags({
            "container": eachArticle,
            "tags": data["tags"]
        });

        /* :::::::::: icons :::::::: */
        let Share = new CreateShareIcons({
            "container": eachArticle,
            "id": data["id"],
            "url": data["url"],
            "printer-friendly-url": data["printer-friendly-url"]
        });

       

        return Scope;

        
    }

    reset () {
        this.container.innerHTML = '';
    }


}