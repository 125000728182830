

//import "../scss/modules/articleList.scss"; 
import CreateArticlePreview from "./CreateArticlePreview.js";

export default class CreateListOfArticles {

    
    constructor (data ) {

        let Scope = this;

     
        let container = document.querySelector(data["container"]);
        Scope.container = container;
        return Scope;

    }

    load (data) {

        let interval = 5;
        let endNumber = 0;

        let articlePreviewContainer = document.createElement("ul");
        articlePreviewContainer.setAttribute("class","article-preview-container");
        this.container.appendChild(articlePreviewContainer);

        let numberOfItem = data.length;
        console.log("number of items: ",numberOfItem);

        if (numberOfItem > 20) {
            startProgressingDisplay (0);

            let moreArticleBtn = document.createElement("div");
            moreArticleBtn.setAttribute("class","more-article");
            moreArticleBtn.innerHTML = "more article";
            this.container.appendChild(moreArticleBtn);
            moreArticleBtn.addEventListener("click",(event) => {
                startProgressingDisplay (endNumber,event);
            });
        }

        else {

            data.forEach((element) => {
                new CreateArticlePreview ({
                    "id": element["id"],
                    "type": element["type"],
                    "date": element["date"],
                    "title": element["title"],
                    "summary": element["summary"],
                    "container": articlePreviewContainer,
                    "tags": element["tags"],
                    "url": element["url"],
                    "printer-friendly-url": element["printer-friendly-url"]
                })
            });

        }
        
        function startProgressingDisplay (start,event = null) {

            console.group("startProgressiveDisplay");
            console.log("start number: ", start);

            for (let i = start; i < (start + interval); i++) {
                try {
                    let element = data[i];
                    new CreateArticlePreview ({
                        "id": element["id"],
                        "type": element["type"],
                        "date": element["date"],
                        "title": element["title"],
                        "summary": element["summary"],
                        "container": articlePreviewContainer,
                        "tags": element["tags"],
                        "url": element["url"],
                        "printer-friendly-url": element["printer-friendly-url"]
                    })
                }
                catch (Error) {
                    event.target.classList.add("disable");                    
                }
              
            }

            endNumber = start + interval;

            console.log("end number: ", endNumber);

            console.groupEnd();

        }

    }

    reset () {
       
        this.container.innerHTML = '';
    }


}